import type { AnchorHTMLAttributes } from 'react';

import { A, H2, H3, H4, H5 } from '../text';

export const MarkdownComponents = {
  a: ({ href, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
    return <A openInNewTab={href?.includes('://') ?? false} {...rest} />;
  },
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
};
